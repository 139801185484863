/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";

const S_Lifestyle = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [featureImage, setFeatureImage] = useState({ preview: "" });

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/lifestyle",
      outputName: "Lifestyle",
      headerName: "Lifestyle",
      tags: ["Landing Page Approved"],
      description: "The Lifestyle Section is a full-width parallax image for aesthetic display.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width: 2048px, Height: 960px"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EaeXrtAhJzxOvqU3VnPkBAwBQmO0lEM5VgZqieeMonnahg?e=TVrR4i",
        comments: [["Lifestyle Image Size", ["2048px x 960px"]]],
      },
    };
    setAppState(fbVars);

    setContentOptions({
      extraContent: {
        label: "Show with example content [Testing Only]",
        checkbox: true,
        field: null,
      },
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: true,
      viewHeight: "400",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!appState || !contentOptions) return "...Loading Lifestyle";
  return (
    <>
      {contentOptions &&
      contentOptions.extraContent &&
      contentOptions.extraContent.checkbox === true ? (
        <div className="l-inner">
          <h2 className="u-mt u-mb u-txt-center">Heading Example</h2>
          <h4>Example of a paragraph</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea consequuntur.
            Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim maxime veritatis
            ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus, est. Delectus
            repellat, quibusdam quidem, pariatur veritatis aspernatur nobis sed a minus autem ut,
            totam corrupti debitis. Lorem ipsum dolor sit amet, consectetur adipisicing elit.{" "}
          </p>
        </div>
      ) : (
        ""
      )}
      <DropZone setImage={setFeatureImage}>
        <section className="s-lifestyle">
          <div
            className="s-lifestyle__parallax"
            data-bg="https://media.kingston.com/kingston/lifestyle/ktc-lifestyle-blog-servers-and-data-centers-nas-storage-optimizing-content-creation.jpg"
            data-speed="3"
            style={{
              backgroundImage: featureImage.preview
                ? `url("` + featureImage.preview + `")`
                : `url("https://media.kingston.com/kingston/lifestyle/ktc-lifestyle-blog-servers-and-data-centers-nas-storage-optimizing-content-creation.jpg")`,
            }}
          ></div>
        </section>
      </DropZone>
      {contentOptions &&
      contentOptions.extraContent &&
      contentOptions.extraContent.checkbox === true ? (
        <div className="l-inner">
          <div className="u-mt u-mb">
            <h4>Example of a paragraph</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
              consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim
              maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus,
              est. Delectus repellat, quibusdam quidem, pariatur veritatis aspernatur nobis sed a
              minus autem ut, totam corrupti debitis. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit.{" "}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "lifestyle",
  component: S_Lifestyle,
  navtxt: "Lifestyle",
  htmlName: "Lifestyle",
  categoryType: ["landing page", "web"],
};
