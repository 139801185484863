/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import { footerOpts } from "./footerOpts";

const S_Footer = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [showForm, setShowForm] = useState(true);
  const [wrap, setWrap] = useState(false);
  const [sectionWidth, setSectionWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [currList, setCurrList] = useState(null);

  let sectContainer = useRef();
  let col1 = useRef();
  let col2 = useRef();
  let col3 = useRef();
  let col4 = useRef();
  let col5 = useRef();
  let col6 = useRef();
  let col7 = useRef();

  // Prevent too many rendering using useCallback
  const handleResize = useCallback(() => {
    setSectionWidth(sectContainer?.current?.getBoundingClientRect().width - 64 || 0);
    let cols = [
      col1.current,
      col2.current,
      col3.current,
      col4.current,
      col5.current,
      col6.current,
      col7.current,
    ];

    let c = 0;
    cols.map((col) => {
      setContentWidth((c += col?.offsetWidth));
      // console.log("col width: ", col.offsetWidth);
    });
  }, [sectContainer.offsetWidth]);

  //opens and closes accordions on mobile and tablet
  const handleToggle = (num) => {
    currList === num ? setCurrList(null) : setCurrList(num);
  };

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let footerVars = {
      ...appState,
      currentPath: "/ui/footer",
      outputName: "Footer",
      headerName: "Footer",
      tags: null,
      description:
        "The Footer Section is a small area at the bottom of the page that provides access to important information and links.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/dialog.component.js",
        "../../js/footer.section.js",
        "../../js/formsLegacy.component.js",
        "../../js/tooltip.component.js",
      ],
      specs: [
        [
          "Headings",
          ["These have different styling than the global <a> tag. Wrapped in <span> tags"],
        ],
        [
          "Links",
          [
            "Any number of links necessary (Optional). Standard text, will use the <a> tag with inherit global styling. Wrapped within <li> tags",
          ],
        ],
        ["Newsletter CTA Paragraph", ["Standard Text, inherits the global P tag styling."]],
        ["Social Media", ["Social Media logos in SVG format with related URL for linking"]],
        ["Additional Copy", ["Optional additional copy area with allowed links."]],
        [
          "Copyright text paragraph",
          ["Standard string at 11px font size that acts as a button for a pop up menu."],
        ],
        [
          "Location text",
          [
            "Standard string in a span, at 95% (14px) font size that displays the user's assigned location for shop purposes.",
          ],
        ],
        ["Email pop-up", ["Recommended limits are 2 lines for the Title and copies."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EWfxuJEV7FpPvtVJS0Bkv2UBOWP8Nr-QQX0LdUBEVILjhQ?e=qObCMf",
        comments: null,
      },
    };
    setAppState(footerVars);
    setContentOptions(footerOpts);
    setDimensions({
      ...dimensions,
      viewHeight: "500",
      hardcodeHeight: true,
      width: window.innerWidth - 24,
    });
  }, []);

  useEffect(() => {
    handleResize();

    sectionWidth < contentWidth ? setWrap(true) : setWrap(false);
  }, [dimensions, sectionWidth, contentWidth]);

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading Footer";
  return (
    <>
      <section className={`s-footer ` + appState.currentTheme}>
        <div className="l-grid">
          <div
            className={`s-footer__links l-row ${wrap ? " s-footer__links--left" : ""}`}
            ref={sectContainer}
          >
            <div className="s-footer__list" ref={col1}>
              <div
                className={`s-footer__links__section${
                  currList === 1 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading1 && contentOptions.heading1.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => {
                      handleToggle(1);
                    }}
                    role={"button"}
                  >
                    {contentOptions.heading1 && contentOptions.heading1.checkbox ? (
                      <span>{contentOptions.heading1.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link1 && contentOptions.link1.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link1 &&
                          contentOptions.link1.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link2 && contentOptions.link2.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link2 &&
                          contentOptions.link2.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link3 && contentOptions.link3.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link3 &&
                          contentOptions.link3.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link4 && contentOptions.link4.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link4 &&
                          contentOptions.link4.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link5 && contentOptions.link5.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link5 &&
                          contentOptions.link5.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link6 && contentOptions.link6.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link6 &&
                          contentOptions.link6.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list" ref={col2}>
              <div
                className={`s-footer__links__section${
                  currList === 2 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading2 && contentOptions.heading2.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => handleToggle(2)}
                    role={"button"}
                  >
                    {contentOptions.heading2 && contentOptions.heading2.checkbox ? (
                      <span>{contentOptions.heading2.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link7 && contentOptions.link7.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link7 &&
                          contentOptions.link7.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link8 && contentOptions.link8.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link8 &&
                          contentOptions.link8.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link9 && contentOptions.link9.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link9 &&
                          contentOptions.link9.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link10 && contentOptions.link10.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link10 &&
                          contentOptions.link10.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link11 && contentOptions.link11.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link11 &&
                          contentOptions.link11.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link12 && contentOptions.link12.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link12 &&
                          contentOptions.link12.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list" ref={col3}>
              <div
                className={`s-footer__links__section${
                  currList === 3 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading3 && contentOptions.heading3.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => handleToggle(3)}
                    role={"button"}
                  >
                    {contentOptions.heading3 && contentOptions.heading3.checkbox ? (
                      <span>{contentOptions.heading3.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link13 && contentOptions.link13.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link13 &&
                          contentOptions.link13.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link14 && contentOptions.link14.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link14 &&
                          contentOptions.link14.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link15 && contentOptions.link15.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link15 &&
                          contentOptions.link15.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link16 && contentOptions.link16.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link16 &&
                          contentOptions.link16.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link17 && contentOptions.link17.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link17 &&
                          contentOptions.link17.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link18 && contentOptions.link18.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link18 &&
                          contentOptions.link18.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list" ref={col4}>
              <div
                className={`s-footer__links__section${
                  currList === 4 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading4 && contentOptions.heading4.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => handleToggle(4)}
                    role={"button"}
                  >
                    {contentOptions.heading4 && contentOptions.heading4.checkbox ? (
                      <span>{contentOptions.heading4.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link19 && contentOptions.link19.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link19 &&
                          contentOptions.link19.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link20 && contentOptions.link20.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link20 &&
                          contentOptions.link20.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link21 && contentOptions.link21.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link21 &&
                          contentOptions.link21.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link22 && contentOptions.link22.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link22 &&
                          contentOptions.link22.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link23 && contentOptions.link23.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link23 &&
                          contentOptions.link23.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link24 && contentOptions.link24.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link24 &&
                          contentOptions.link24.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list" ref={col5}>
              <div
                className={`s-footer__links__section${
                  currList === 5 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading5 && contentOptions.heading5.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => handleToggle(5)}
                    role={"button"}
                  >
                    {contentOptions.heading5 && contentOptions.heading5.checkbox ? (
                      <span>{contentOptions.heading5.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link25 && contentOptions.link25.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link25 &&
                          contentOptions.link25.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link26 && contentOptions.link26.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link26 &&
                          contentOptions.link26.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link27 && contentOptions.link27.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link27 &&
                          contentOptions.link27.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link28 && contentOptions.link28.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link28 &&
                          contentOptions.link28.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link29 && contentOptions.link29.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link29 &&
                          contentOptions.link29.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link30 && contentOptions.link30.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link30 &&
                          contentOptions.link30.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list" ref={col6}>
              <div
                className={`s-footer__links__section${
                  currList === 6 ? " s-footer__list__section--active" : ""
                }`}
              >
                {contentOptions.heading6 && contentOptions.heading6.checkbox ? (
                  <div
                    className="s-footer__list__heading"
                    aria-expanded={false}
                    onClick={() => handleToggle(6)}
                    role={"button"}
                  >
                    {contentOptions.heading6 && contentOptions.heading6.checkbox ? (
                      <span>{contentOptions.heading6.field[contentOptions.currentLang]}</span>
                    ) : (
                      ""
                    )}
                    <div className="s-footer__list__section__indicator">
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#plus"
                        ></use>
                      </svg>
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#minus"
                        ></use>
                      </svg>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="s-footer__list__container" aria-hidden={true}>
                  {contentOptions.link31 && contentOptions.link31.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link31 &&
                          contentOptions.link31.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link32 && contentOptions.link32.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link32 &&
                          contentOptions.link32.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link33 && contentOptions.link33.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link33 &&
                          contentOptions.link33.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link34 && contentOptions.link34.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link34 &&
                          contentOptions.link34.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link35 && contentOptions.link35.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link35 &&
                          contentOptions.link35.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {contentOptions.link36 && contentOptions.link36.checkbox ? (
                    <li>
                      <a href="#itemLink" title="Anchor Title Text">
                        {contentOptions.link36 &&
                          contentOptions.link36.field[contentOptions.currentLang]}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="s-footer__list s-footer__list--email" ref={col7}>
              <div className="s-footer__list__section">
                <span className="s-footer__list__heading" aria-expanded={false}>
                  {contentOptions.ctaHeading &&
                    contentOptions.ctaHeading.field[contentOptions.currentLang]}
                </span>
                <div className="s-footer__list__section__h5">
                  <span>
                    {contentOptions.ctaSubheading &&
                      contentOptions.ctaSubheading.field[contentOptions.currentLang]}
                  </span>
                </div>
                <ul className="s-footer__list__container" aria-hidden={true}>
                  <li>
                    <div className="s-footer__cta">
                      <p>
                        {contentOptions.ctaText &&
                          contentOptions.ctaText.field[contentOptions.currentLang]}
                      </p>
                      <button
                        className="e-btn"
                        title="Button Title"
                        data-popup="emailPopUp"
                        onClick={() => setShowForm(!showForm)}
                      >
                        <span>
                          {contentOptions.ctaBtn &&
                            contentOptions.ctaBtn.field[contentOptions.currentLang]}
                        </span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="l-row l-row--center">
          <ul
            className="s-footer__social u-list-unstyled u-list-horizontal"
            aria-label="List of Socials"
          >
            {contentOptions.socials &&
            contentOptions.socials[0] &&
            contentOptions.socials[0].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#facebook" aria-label="Facebook" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[0].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}

            {contentOptions.socials &&
            contentOptions.socials[1] &&
            contentOptions.socials[1].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#x" aria-label="X" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[1].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}

            {contentOptions.socials &&
            contentOptions.socials[2] &&
            contentOptions.socials[2].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#linkedin" aria-label="Linkedin" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[2].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}

            {contentOptions.socials &&
            contentOptions.socials[3] &&
            contentOptions.socials[3].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#youtube" aria-label="Youtube" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[3].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}

            {contentOptions.socials &&
            contentOptions.socials[4] &&
            contentOptions.socials[4].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#instagram" aria-label="Instagram" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[4].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}

            {contentOptions.socials &&
            contentOptions.socials[5] &&
            contentOptions.socials[5].checkbox ? (
              <li className="c-socials__list__item">
                <a href="#tiktok" aria-label="TikTok" target="_blank">
                  <svg aria-hidden="true">
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref={`/images/icons-map.svg${contentOptions.socials[5].field}`}
                    ></use>
                  </svg>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div className="s-footer__copyright">
          <div className="s-footer__copyright--text">
            <p className="u-m0">
              {contentOptions.Copy && contentOptions.Copy.field[contentOptions.currentLang]}
            </p>
          </div>
          <div className="s-footer__shop">
            <svg width="25" height="25" viewBox="0 0 25 25">
              <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M7.05279391,13.0010636 L3.05500569,13.0006955 C3.46835632,16.7372697 6.16921492,19.7842959 9.72607502,20.7102675 C8.2052792,18.4233136 7.28166193,15.7770381 7.05279391,13.0010636 Z M20.9449943,13.0006955 L16.9498422,13.0008226 C16.7255335,15.7635472 15.8008579,18.4154858 14.2745676,20.7097575 C17.8312897,19.7837266 20.5316823,16.7369207 20.9449943,13.0006955 Z M14.945194,13.0003647 L9.05765422,13.0006768 C9.30895924,15.713613 10.3304698,18.2982679 12.0000219,20.4492318 C13.6759302,18.2904611 14.6988433,15.6975901 14.945194,13.0003647 Z M9.72543244,3.29024251 L9.6892238,3.29941009 C6.15025532,4.23685972 3.46644779,7.27618877 3.05489527,11.0003032 L7.05007662,11.0001782 C7.27424317,8.2370933 8.19895783,5.58479128 9.72543244,3.29024251 Z M11.9999781,3.5507682 C10.3238625,5.70980581 9.30090373,8.30305111 9.05471464,11.0006358 L14.9424384,11.0003233 C14.6912918,8.28702224 13.6697354,5.70199636 11.9999781,3.5507682 Z M14.273925,3.2897325 L14.4629614,3.58063524 C15.8714653,5.80010201 16.728192,8.34051478 16.9472885,10.9999359 L20.9451047,11.0003032 C20.5321249,7.26327319 17.831102,4.21578659 14.273925,3.2897325 Z"></path>
            </svg>
            <span className="s-footer__shop__text">
              {contentOptions.location && contentOptions.location.field[contentOptions.currentLang]}
            </span>
          </div>
        </div>
      </section>
      {showForm ? (
        <div
          className="c-dialog u-autoMargin c-dialog--form c-dialog--popup c-dialog--active"
          tabIndex="0"
          id="emailPopUp"
          aria-labelledby="dialogHeader0"
        >
          <header className="c-dialog__header" role="heading" id="dialogHeader0">
            <button
              className="c-dialog__header__close"
              aria-label="close"
              data-close="true"
              data-popup="emailPopUp"
              onClick={() => setShowForm(!showForm)}
            >
              <svg viewBox="0 0 14 14">
                <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
              </svg>
            </button>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
          </header>
          <div className="c-dialog__content">
            <p>
              Lorem ipsum dolor sit amet
              <a
                href="#"
                className="c-tooltip c-tooltip--top"
                data-toolid="kingston"
                role="tooltip"
                aria-describedby="kingston"
              >
                &nbsp;Kingston&nbsp;
              </a>
              consectetur adipiscing elit, sed do.
            </p>
            <div className="c-tooltip__message" id="kingston">
              <p>The tooltip&apos;s message will appear above the SPAN or ANCHOR tag.</p>
            </div>
            <div className="c-dialog__content__radio">
              <div className="f-input">
                <div className="f-input__checkbox">
                  <input type="checkbox" name="checkboxName" id="checkboxName0" value="Personal" />
                  <label htmlFor="checkboxName0" aria-checked="false" tabIndex="0">
                    Lorem
                  </label>
                </div>
                <div className="f-input__checkbox">
                  <input type="checkbox" name="checkboxName" id="checkboxName1" value="Gaming" />
                  <label htmlFor="checkboxName1" aria-checked="false" tabIndex="0">
                    Ipsum
                  </label>
                </div>
                <div className="f-input__checkbox">
                  <input
                    type="checkbox"
                    name="checkboxName"
                    id="checkboxName2"
                    value="Commercial"
                  />
                  <label htmlFor="checkboxName2" aria-checked="false" tabIndex="0">
                    Dolor
                  </label>
                </div>
              </div>
            </div>
            <form id="js-collapseExample">
              <div className="f-input">
                <div className="f-input__string">
                  <input type="text" name="inputName" id="inputName" />
                  <label htmlFor="inputName">Email Address</label>
                </div>
              </div>
              <button
                className="e-btn"
                title="Button Title"
                id="submitBtnId"
                data-collapse="js-collapseExample"
              >
                <span>Cta Here</span>
              </button>
            </form>
            <p className="c-dialog__content--info">
              Optional marketing message here with possible &nbsp;
              <a href="#">
                link
              </a>
              .
            </p>

            <p id="demoMsg" style={{ display: "none", opacity: "0", transition: "opacity .5s" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="reCAPTCHA">
              Place 3rd party privacy or reCAPTCHA message&nbsp;
              <a href="#">
                Google Privacy Policy
              </a>&nbsp;
              and&nbsp;
              <a href="#">
               Terms of Service
              </a>
              here.
            </p>
            <p>
              Place for kingston data usage and &nbsp;
              <a href="#">privacy policy</a>. et &apos;unsubscribe&apos; method or other
              disclaimer here for newsletter signup.
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "footer",
  component: S_Footer,
  navtxt: "Footer",
  htmlName: "Footer",
  categoryType: ["web"],
};
