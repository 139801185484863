import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import faqOpts from "../S_Faqs/faqOpts";

const S_Accordion = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [currentPanel, setCurrentPanel] = useState("");
  const [currentSidePanel, setCurrentSidePanel] = useState("");
  const [mobileExpand, setMobileExpand] = useState(false);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const panel1Ref = useRef(null);
  const panel2aRef = useRef(null);
  const panel2bRef = useRef(null);
  const panel3Ref = useRef(null);
  const panel4Ref = useRef(null);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let faqsVars = {
      ...appState,
      currentPath: "/ui/accordion",
      outputName: "Accordion", //html name
      headerName: "Accordion",
      tags: null,
      description:
        "The FAQs section has two main components for dynamic content, the side tabs and accordions. The set of questions displayed on the right correlates to the topic the user clicks on from the side tabs on the left. The accordion questions also contain an optional rating component. Please see the default view for how the UI animation should look.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/accordion.component.js",
      ],
      specs: [],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1PV_MZwco2jdnB10Z0LjfjX5Wok2E8nWyq2P-AVcfbP8/edit?usp=sharing",
        comments: null,
      },
    };
    setAppState(faqsVars);
    setContentOptions(faqOpts);
    setDimensions({ ...dimensions, hardcodeHeight: true, viewHeight: "850" });
    setCurrentPanel("accPanel-3");
  }, []);

  function handleToggle(faq) {
    faq == currentPanel ? setCurrentPanel("") : setCurrentPanel(faq);
  }

  const getPanelHeight = (panel) => {
    if (panel && panel !== null && panel.current) {
      const clone = panel.current.cloneNode(true);
      let pHeight = "";
      clone.style.position = "absolute";
      clone.style.visibility = "hidden";
      document.body.appendChild(clone);
      pHeight = clone.offsetHeight;
      console.log("pHeight: ", pHeight);
      document.body.removeChild(clone);
      return pHeight;
    }
  };
  function handleSideToggle(topic) {
    topic == setCurrentSidePanel(topic);
  }

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading FAQs";
  return (
    <section className={"s-testing " + appState.currentTheme}>
      <div className="l-inner">
        <div className="c-accordion" data-multiselect="true">
          <span className={`c-accordion__tab`} onClick={() => handleToggle("accPanel-1")}>
            <button>
              Accordion Tab 1
              <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
              </svg>
            </button>
          </span>
          <div
            className="c-accordion__panel"
            style={{ maxHeight: currentPanel === "accPanel-1" ? "max-content" : null }}
          >
            <div className="c-accordion__panel__content"></div>
          </div>
        </div>
        <div className="c-accordion" data-multiselect="false">
          <span
            className={`c-accordion__tab${
              currentPanel == "accPanel-2" ? " c-accordion__tab--active" : ""
            }`}
            id="accTab-2a"
            onClick={() => handleToggle("accPanel-2a")}
          >
            <button
              aria-controls="accPanel-2a"
              aria-expanded="false"
              onClick={() => handleToggle("accPanel-2a")}
            >
              Accordion Tab 2
              <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
              </svg>
            </button>
          </span>
          <div
            className="c-accordion__panel"
            id="accPanel-2a"
            aria-labelledby="accTab-2a"
            ref={panel2aRef}
            style={{
              visibility: currentPanel === "accPanel-2a" ? "visible" : "hidden",
              maxHeight: currentPanel === "accPanel-2a" ? "max-content" : null,
            }}
          >
            <div className="c-accordion__panel__content">
              <div className="c-accordion">
                <span
                  className="c-accordion__tab c-accordion__tab--active"
                  id="accTab-2b"
                  onClick={() => handleToggle("accPanel-2b")}
                >
                  <button>test</button>
                </span>
                <div
                  className="c-accordion__panel"
                  id="accPanel-2b"
                  ref={panel2bRef}
                  style={{
                    visibility: currentPanel === "accPanel-2b" ? "visible" : "hidden",
                    maxHeight: currentPanel === "accPanel-2b" ? "max-content" : null,
                  }}
                >
                  <div className="c-accordion__panel__content">
                    <p>
                      fjsdlfjs;Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus dapibus
                      adipiscing euismod; suspendisse senectus volutpat. Vivamus feugiat urna
                      lobortis penatibus quis nisi congue. Montes maecenas sagittis vestibulum
                      nostra mattis class dictum magna. Sit eget magnis congue viverra orci
                      faucibus. In in erat blandit sociosqu leo. Placerat suscipit imperdiet
                      ullamcorper himenaeos habitasse augue duis. Nulla condimentum ac ex etiam
                      efficitur dolor. Vestibulum urna vel fusce ad platea vestibulum nibh faucibus.
                      Nam parturient laoreet ornare, posuere nisi vel inceptos. Est ridiculus
                      pulvinar lobortis hac lacinia libero non blandit. Fames proin tortor mattis
                      turpis blandit aenean massa massa. Fermentum per nisl ipsum massa imperdiet
                      integer semper. Varius lobortis fames ad elementum mollis cursus. Id quam
                      primis porta, lacus sem etiam. Nisi non tortor consequat conubia vivamus
                      cubilia. Parturient auctor nostra mus mauris rhoncus quam. Arcu vestibulum
                      proin augue inceptos rhoncus, tempor leo netus. Posuere null;ajf
                    </p>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus dapibus adipiscing
                euismod; suspendisse senectus volutpat. Vivamus feugiat urna lobortis penatibus quis
                nisi congue. Montes maecenas sagittis vestibulum nostra mattis class dictum magna.
                Sit eget magnis congue viverra orci faucibus. In in erat blandit sociosqu leo.
                Placerat suscipit imperdiet ullamcorper himenaeos habitasse augue duis. Nulla
                condimentum ac ex etiam efficitur dolor. Vestibulum urna vel fusce ad platea
                vestibulum nibh faucibus. Nam parturient laoreet ornare, posuere nisi vel inceptos.
                Est ridiculus pulvinar lobortis hac lacinia libero non blandit. Fames proin tortor
                mattis turpis blandit aenean massa massa. Fermentum per nisl ipsum massa imperdiet
                integer semper. Varius lobortis fames ad elementum mollis cursus. Id quam primis
                porta, lacus sem etiam. Nisi non tortor consequat conubia vivamus cubilia.
                Parturient auctor nostra mus mauris rhoncus quam. Arcu vestibulum proin augue
                inceptos rhoncus, tempor leo netus. Posuere nulla dictum, eleifend nostra curabitur
                class sapien. Fusce porta facilisis natoque curae erat tellus. Ac a sem nostra odio
                iaculis. Accumsan sagittis tristique quisque rutrum sociosqu cras. Enim tincidunt
                conubia aenean facilisis sagittis id laoreet parturient lectus. Adipiscing nec
                fringilla consectetur proin convallis phasellus fames cursus. Non feugiat
                ullamcorper venenatis adipiscing integer; potenti cras ultricies. Nisi eleifend
                elementum rhoncus eu aenean! Ante senectus volutpat fusce iaculis; rutrum sagittis
                taciti. Class leo cursus tempus turpis elit mi. Semper nostra nibh platea tempus
                nullam varius bibendum. Senectus nulla fringilla commodo varius potenti turpis.
                Sagittis nulla condimentum habitant ipsum cras iaculis habitasse et. Efficitur morbi
                interdum sed feugiat purus semper magnis. Posuere ridiculus arcu laoreet non fames
                habitant nulla. Scelerisque imperdiet aptent dolor vehicula platea tincidunt
                senectus quam. Mattis purus natoque suspendisse congue magnis, luctus elementum
                ipsum? Nunc taciti integer parturient tellus velit curae taciti consequat. Dictum
                arcu habitasse eros nisi enim eros facilisis sed egestas. Arcu maximus magnis class
                habitasse, fames consectetur. Semper nascetur magnis donec justo vulputate rhoncus
                feugiat montes.
              </p>
            </div>
          </div>
          <span
            className={`c-accordion__tab c-accordion__tab--active${
              currentPanel == "accPanel-3" ? " c-accordion__tab--active" : ""
            }`}
            id="accTab-3"
          >
            <button
              aria-controls="accPanel-3"
              aria-expanded="false"
              onClick={() => handleToggle("accPanel-3")}
            >
              Accordion Tab 3
              <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
              </svg>
            </button>
          </span>
          <div
            className="c-accordion__panel"
            id="accPanel-3"
            aria-labelledby="accTab-3"
            ref={panel3Ref}
            style={{ maxHeight: currentPanel === "accPanel-3" ? "max-content" : null }}
          >
            <div className="c-accordion__panel__content something" style={{ maxHeight: "18em" }}>
              <p>
                Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus dapibus adipiscing
                euismod; suspendisse senectus volutpat. Vivamus feugiat urna lobortis penatibus quis
                nisi congue. Montes maecenas sagittis vestibulum nostra mattis class dictum magna.
                Sit eget magnis congue viverra orci faucibus. In in erat blandit sociosqu leo.
                Placerat suscipit imperdiet ullamcorper himenaeos habitasse augue duis. Nulla
                condimentum ac ex etiam efficitur dolor. Vestibulum urna vel fusce ad platea
                vestibulum nibh faucibus. Nam parturient laoreet ornare, posuere nisi vel inceptos.
                Est ridiculus pulvinar lobortis hac lacinia libero non blandit. Fames proin tortor
                mattis turpis blandit aenean massa massa. Fermentum per nisl ipsum massa imperdiet
                integer semper. Varius lobortis fames ad elementum mollis cursus. Id quam primis
                porta, lacus sem etiam. Nisi non tortor consequat conubia vivamus cubilia.
                Parturient auctor nostra mus mauris rhoncus quam. Arcu vestibulum proin augue
                inceptos rhoncus, tempor leo netus. Posuere nulla dictum, eleifend nostra curabitur
                class sapien. Fusce porta facilisis natoque curae erat tellus. Ac a sem nostra odio
                iaculis. Accumsan sagittis tristique quisque rutrum sociosqu cras. Enim tincidunt
                conubia aenean facilisis sagittis id laoreet parturient lectus. Adipiscing nec
                fringilla consectetur proin convallis phasellus fames cursus. Non feugiat
                ullamcorper venenatis adipiscing integer; potenti cras ultricies. Nisi eleifend
                elementum rhoncus eu aenean! Ante senectus volutpat fusce iaculis; rutrum sagittis
                taciti. Class leo cursus tempus turpis elit mi. Semper nostra nibh platea tempus
                nullam varius bibendum. Senectus nulla fringilla commodo varius potenti turpis.
                Sagittis nulla condimentum habitant ipsum cras iaculis habitasse et. Efficitur morbi
                interdum sed feugiat purus semper magnis. Posuere ridiculus arcu laoreet non fames
                habitant nulla. Scelerisque imperdiet aptent dolor vehicula platea tincidunt
                senectus quam. Mattis purus natoque suspendisse congue magnis, luctus elementum
                ipsum? Nunc taciti integer parturient tellus velit curae taciti consequat. Dictum
                arcu habitasse eros nisi enim eros facilisis sed egestas. Arcu maximus magnis class
                habitasse, fames consectetur. Semper nascetur magnis donec justo vulputate rhoncus
                feugiat montes.
              </p>
            </div>
          </div>
          <span
            className={`c-accordion__tab${
              currentPanel == "accPanel-4" ? " c-accordion__tab--active" : ""
            }`}
            id="accTab-4"
          >
            <button
              aria-controls="accPanel-4"
              aria-expanded="false"
              onClick={() => handleToggle("accPanel-4")}
            >
              Accordion Tab 4
              <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
              </svg>
            </button>
          </span>
          <div
            className="c-accordion__panel"
            id="accPanel-4"
            aria-labelledby="accTab-4"
            ref={panel4Ref}
            style={{ maxHeight: currentPanel === "accPanel-4" ? "max-content" : null }}
          >
            <div className="c-accordion__panel__content">
              <p>Something</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_.
export default {
  path: "accordion",
  component: S_Accordion,
  navtxt: "Accordion",
  htmlName: "Accordion",
  categoryType: ["internal"],
};
