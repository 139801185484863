import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import E_ArrowCTA from "../../1_elements/arrowCTA/E_ArrowCTA";
import C_Rating from "../../2_components/rating/C_Rating";
import faqOpts from "./faqOpts";

const S_Faqs = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [currentPanel, setCurrentPanel] = useState("");
  const [currentSidePanel, setCurrentSidePanel] = useState("");
  const [mobileExpand, setMobileExpand] = useState(false);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const panel1Ref = useRef(null);
  const panel2Ref = useRef(null);
  const panelu7bm8bRef = useRef(null);
  const panelmz9iqpRef = useRef(null);
  const panel4Ref = useRef(null);
  const panel5Ref = useRef(null);
  const panel6Ref = useRef(null);
  const panel7Ref = useRef(null);
  const panel8Ref = useRef(null);
  const panel9Ref = useRef(null);
  const panel10Ref = useRef(null);
  const panel11Ref = useRef(null);
  const panel12Ref = useRef(null);
  const panel13Ref = useRef(null);
  const panel14Ref = useRef(null);
  const panel15Ref = useRef(null);
  const panel16Ref = useRef(null);
  const panel17Ref = useRef(null);
  const panel18Ref = useRef(null);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let faqsVars = {
      ...appState,
      currentPath: "/ui/faqs",
      outputName: "Faqs", //html name
      headerName: "FAQS",
      tags: null,
      description:
        "The FAQs section has two main components for dynamic content, the side tabs and accordions. The set of questions displayed on the right correlates to the topic the user clicks on from the side tabs on the left. The accordion questions also contain an optional rating component.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/accordion.component.js",
        "../../js/rating.component.js",
        "../../js/sideTabs.component.js",
      ],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, and Large: 16px x 16px. No repeating icons"],
        ],
        ["Image Type", ["SVG"]],
        [
          "Topic",
          ["String text, no character limit but recommend max 2 lines. Topics should be concise."],
        ],
        [
          "Subtitle",
          ["H3 tag that acts as a heading for each accordion group of questions. (Optional)"],
        ],
        ["Question", ["String text, no character limit but recommend max 2-3 lines."]],
        ["Answer", ["Can contain string, images, and rating component."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EZswHwyd_QdHsXin6IPG4SkBxBV-7wk0NZA9qrM0ee-4mQ?e=iIR8ZZ",
        comments: null,
      },
    };
    setAppState(faqsVars);
    setContentOptions(faqOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "" });
    setCurrentSidePanel("sideTabs__tab-1");
  }, []);

  function handleToggle(faq) {
    faq == currentPanel ? setCurrentPanel("") : setCurrentPanel(faq);
  }

  const getPanelHeight = (panel) => {
    if (panel && panel !== null && panel.current) {
      const clone = panel.current.cloneNode(true);
      let pHeight = "";
      clone.style.position = "absolute";
      clone.style.visibility = "hidden";
      document.body.appendChild(clone);
      pHeight = clone.offsetHeight;
      document.body.removeChild(clone);
      return pHeight;
    }
  };

  function handleSideToggle(topic) {
    topic == setCurrentSidePanel(topic);
  }

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading FAQs";
  return (
    <section className={"s-faqs " + appState.currentTheme}>
      {contentOptions.sidetab && contentOptions.sidetab.checkbox ? (
        <div className="l-inner">
          {contentOptions.subtitle && contentOptions.subtitle.checkbox ? (
            <h3>
              {contentOptions.subtitle && contentOptions.subtitle.field[contentOptions.currentLang]}
            </h3>
          ) : (
            ""
          )}
          <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
            <span
              className={`c-accordion__tab${
                currentPanel == "accPanel-1" ? " c-accordion__tab--active" : ""
              }`}
              id="accTab-1"
            >
              <button
                aria-controls="accPanel-1"
                aria-expanded="false"
                onClick={() => handleToggle("accPanel-1")}
              >
                {contentOptions.question1 &&
                  contentOptions.question1.field[contentOptions.currentLang]}
                <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                </svg>
              </button>
            </span>
            <div
              className="c-accordion__panel"
              id="accPanel-1"
              aria-labelledby="accTab-1"
              ref={panel1Ref}
              style={{
                maxHeight: currentPanel === "accPanel-1" ? getPanelHeight(panel1Ref) : null,
              }}
            >
              <div className="c-accordion__panel__content">
                <p>
                  {contentOptions.answer1 &&
                    contentOptions.answer1.field[contentOptions.currentLang]}
                </p>
                <p>FAQ: KDT-011411-DVR-02</p>
                <C_Rating />
              </div>
            </div>
            <span
              className={`c-accordion__tab${
                currentPanel == "accPanel-2" ? " c-accordion__tab--active" : ""
              }`}
              id="accTab-2"
            >
              <button
                aria-controls="accPanel-2"
                aria-expanded="false"
                onClick={() => handleToggle("accPanel-2")}
              >
                {contentOptions.question2 &&
                  contentOptions.question2.field[contentOptions.currentLang]}
                <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden={true}>
                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                </svg>
              </button>
            </span>
            <div
              className="c-accordion__panel"
              id="accPanel-2"
              aria-labelledby="accTab-2"
              ref={panel2Ref}
              style={{
                maxHeight: currentPanel === "accPanel-2" ? getPanelHeight(panel2Ref) : null,
              }}
            >
              <div className="c-accordion__panel__content">
                <p>
                  {contentOptions.answer2 &&
                    contentOptions.answer2.field[contentOptions.currentLang]}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="l-inner">
          <div
            className={`c-sideTabs ${
              contentOptions.icons && contentOptions.icons.checkbox ? "" : "c-sideTabs--icons"
            }`}
          >
            <div className=" l-row">
              <div className="l-row__col l-1/3@md">
                <button
                  className="c-sideTabs__mobileSelect"
                  aria-expanded={mobileExpand}
                  onClick={() => setMobileExpand(!mobileExpand)}
                >
                  <span className="c-sideTabs__mobileSelect__value">
                    {contentOptions.icons && contentOptions.icons.checkbox ? (
                      ""
                    ) : (
                      <svg viewBox="0 0 16 16" aria-hidden="true">
                        <path d="M8.725.733c.323.16.585.421.744.744l1.005 2.035a2 2 0 001.505 1.094l2.246.326a1.639 1.639 0 01.908 2.795l-1.625 1.584a2 2 0 00-.575 1.77l.384 2.237a1.639 1.639 0 01-2.378 1.727L8.93 13.99a2 2 0 00-1.862 0L5.06 15.046a1.639 1.639 0 01-2.378-1.727l.384-2.237a2 2 0 00-.575-1.77L.867 7.727a1.639 1.639 0 01.908-2.795l2.246-.326a2 2 0 001.505-1.094l1.005-2.035A1.639 1.639 0 018.725.733zm-.442.897a.639.639 0 00-.802.2l-.054.09-1.004 2.035A3 3 0 014.358 5.56l-.194.035-2.245.326a.639.639 0 00-.422 1.011l.068.078L3.19 8.595a3 3 0 01.89 2.46l-.027.195-.384 2.237a.639.639 0 00.831.714l.096-.041 2.008-1.056a3 3 0 012.602-.091l.19.091 2.008 1.056a.639.639 0 00.936-.58l-.009-.093-.384-2.237a3 3 0 01.727-2.513l.136-.142 1.625-1.584a.639.639 0 00-.262-1.069l-.092-.02-2.245-.326A3 3 0 019.67 4.128l-.093-.173L8.573 1.92a.639.639 0 00-.29-.29z" />
                      </svg>
                    )}
                    {contentOptions.sidetabfeature &&
                      contentOptions.sidetabfeature.field[contentOptions.currentLang]}
                  </span>
                  <svg
                    viewBox="0 0 14 8"
                    className="c-sideTabs__mobileSelect__arrow"
                    aria-hidden="true"
                  >
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
                <div className="c-sideTabs__tabs u-list-unstyled" role="tablist">
                  <button
                    id="sideTabs__tab-1"
                    className="c-sideTabs__tabs__tab"
                    role="tab"
                    aria-selected={currentSidePanel == "sideTabs__tab-1" ? "true" : "false"}
                    aria-controls="accPanel-1"
                    onClick={() => handleSideToggle("sideTabs__tab-1")}
                  >
                    {contentOptions.icons && contentOptions.icons.checkbox ? (
                      ""
                    ) : (
                      <svg aria-hidden="true" viewBox="0 0 16 16">
                        <use
                          xlinkHref={`/images/icons-map.svg${
                            contentOptions.icon1 && contentOptions.icon1.field
                          }`}
                        />
                      </svg>
                    )}
                    {contentOptions.sidetabfeature &&
                      contentOptions.sidetabfeature.field[contentOptions.currentLang]}
                  </button>
                  <button
                    id="sideTabs__tab-2"
                    className="c-sideTabs__tabs__tab"
                    role="tab"
                    aria-selected={currentSidePanel == "sideTabs__tab-2" ? "true" : "false"}
                    aria-controls="accPanel-2"
                    tabIndex="-1"
                    onClick={() => handleSideToggle("sideTabs__tab-2")}
                  >
                    {contentOptions.icons && contentOptions.icons.checkbox ? (
                      ""
                    ) : (
                      <svg aria-hidden="true" viewBox="0 0 16 16">
                        <use
                          xlinkHref={`/images/icons-map.svg${
                            contentOptions.icon2 && contentOptions.icon2.field
                          }`}
                        />
                      </svg>
                    )}
                    {contentOptions.tab2 && contentOptions.tab2.field[contentOptions.currentLang]}
                  </button>
                  {contentOptions.tab3 && contentOptions.tab3.checkbox ? (
                    <button
                      id="sideTabs__tab-3"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-3" ? "true" : "false"}
                      aria-controls="accPanel-3"
                      onClick={() => handleSideToggle("sideTabs__tab-3")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab3.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon3 && contentOptions.icon3.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab3 &&
                        contentOptions.tab3.checkbox &&
                        contentOptions.tab3.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab4 && contentOptions.tab4.checkbox ? (
                    <button
                      id="sideTabs__tab-4"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-4" ? "true" : "false"}
                      aria-controls="accPanel-4"
                      onClick={() => handleSideToggle("sideTabs__tab-4")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab4.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon4 && contentOptions.icon4.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab4 &&
                        contentOptions.tab4.checkbox &&
                        contentOptions.tab4.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab5 && contentOptions.tab5.checkbox ? (
                    <button
                      id="sideTabs__tab-5"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-5" ? "true" : "false"}
                      aria-controls="accPanel-5"
                      onClick={() => handleSideToggle("sideTabs__tab-5")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab5.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon5 && contentOptions.icon5.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab5 &&
                        contentOptions.tab5.checkbox &&
                        contentOptions.tab5.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab6 && contentOptions.tab6.checkbox ? (
                    <button
                      id="sideTabs__tab-6"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-6" ? "true" : "false"}
                      aria-controls="accPanel-6"
                      onClick={() => handleSideToggle("sideTabs__tab-6")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab6.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon6 && contentOptions.icon6.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab6 &&
                        contentOptions.tab6.checkbox &&
                        contentOptions.tab6.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab7 && contentOptions.tab7.checkbox ? (
                    <button
                      id="sideTabs__tab-7"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-7" ? "true" : "false"}
                      aria-controls="accPanel-7"
                      onClick={() => handleSideToggle("sideTabs__tab-7")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab7.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon7 && contentOptions.icon7.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab7 &&
                        contentOptions.tab7.checkbox &&
                        contentOptions.tab7.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab8 && contentOptions.tab8.checkbox ? (
                    <button
                      id="sideTabs__tab-8"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-8" ? "true" : "false"}
                      aria-controls="accPanel-8"
                      onClick={() => handleSideToggle("sideTabs__tab-8")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab8.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon8 && contentOptions.icon8.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab8 &&
                        contentOptions.tab8.checkbox &&
                        contentOptions.tab8.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                  {contentOptions.tab9 && contentOptions.tab9.checkbox ? (
                    <button
                      id="sideTabs__tab-9"
                      className="c-sideTabs__tabs__tab"
                      role="tab"
                      tabIndex="-1"
                      aria-selected={currentSidePanel == "sideTabs__tab-9" ? "true" : "false"}
                      aria-controls="accPanel-9"
                      onClick={() => handleSideToggle("sideTabs__tab-9")}
                    >
                      {contentOptions.icons &&
                      contentOptions.tab9.checkbox &&
                      contentOptions.icons.checkbox ? (
                        ""
                      ) : (
                        <svg aria-hidden="true" viewBox="0 0 16 16">
                          <use
                            xlinkHref={`/images/icons-map.svg${
                              contentOptions.icon9 && contentOptions.icon9.field
                            }`}
                          />
                        </svg>
                      )}
                      {contentOptions.tab9 &&
                        contentOptions.tab9.checkbox &&
                        contentOptions.tab9.field[contentOptions.currentLang]}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="l-row__col l-2/3@md">
                <div className="c-sideTabs__panels">
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-1" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-1" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-1"
                      >
                        <button
                          aria-controls="accPanel-1"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-1")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-1"
                        aria-labelledby="accTab-1"
                        ref={panel1Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-1" ? getPanelHeight(panel1Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-2" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-2"
                      >
                        <button
                          aria-controls="accPanel-2"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-2")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-2"
                        aria-labelledby="accTab-2"
                        ref={panel2Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-2" ? getPanelHeight(panel2Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-2" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-u7bm8b" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-7wl4oh"
                      >
                        <button
                          aria-controls="accPanel-u7bm8b"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-u7bm8b")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-u7bm8b"
                        aria-labelledby="accTab-7wl4oh"
                        ref={panelu7bm8bRef}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-u7bm8b"
                              ? getPanelHeight(panelu7bm8bRef)
                              : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-mz9iqp" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-xohpir"
                      >
                        <button
                          aria-controls="accPanel-mz9iqp"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-mz9iqp")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-mz9iqp"
                        aria-labelledby="accTab-xohpir"
                        ref={panelmz9iqpRef}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-mz9iqp"
                              ? getPanelHeight(panelmz9iqpRef)
                              : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-3" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-4" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-4"
                      >
                        <button
                          aria-controls="accPanel-4"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-4")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-4"
                        aria-labelledby="accTab-4"
                        ref={panel4Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-4" ? getPanelHeight(panel4Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-5" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-5"
                      >
                        <button
                          aria-controls="accPanel-5"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-5")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-5"
                        aria-labelledby="accTab-5"
                        ref={panel5Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-5" ? getPanelHeight(panel5Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-6" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-6"
                      >
                        <button
                          aria-controls="accPanel-6"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-6")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-6"
                        aria-labelledby="accTab-6"
                        ref={panel6Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-6" ? getPanelHeight(panel6Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-7" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-7"
                      >
                        <button
                          aria-controls="accPanel-7"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-7")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-7"
                        aria-labelledby="accTab-7"
                        ref={panel7Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-7" ? getPanelHeight(panel7Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-4" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-8" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-8"
                      >
                        <button
                          aria-controls="accPanel-8"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-8")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-8"
                        aria-labelledby="accTab-8"
                        ref={panel8Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-8" ? getPanelHeight(panel8Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-5" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-9" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-9"
                      >
                        <button
                          aria-controls="accPanel-9"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-9")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-9"
                        aria-labelledby="accTab-9"
                        ref={panel9Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-9" ? getPanelHeight(panel9Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-10" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-10"
                      >
                        <button
                          aria-controls="accPanel-10"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-10")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-10"
                        aria-labelledby="accTab-10"
                        ref={panel10Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-10" ? getPanelHeight(panel10Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-6" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-11" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-11"
                      >
                        <button
                          aria-controls="accPanel-11"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-11")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-11"
                        aria-labelledby="accTab-11"
                        ref={panel11Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-11" ? getPanelHeight(panel11Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-12" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-12"
                      >
                        <button
                          aria-controls="accPanel-12"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-12")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-12"
                        aria-labelledby="accTab-12"
                        ref={panel12Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-12" ? getPanelHeight(panel12Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-13" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-13"
                      >
                        <button
                          aria-controls="accPanel-13"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-13")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-13"
                        aria-labelledby="accTab-13"
                        ref={panel13Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-13" ? getPanelHeight(panel13Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-7" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-14" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-14"
                      >
                        <button
                          aria-controls="accPanel-14"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-1")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-14"
                        aria-labelledby="accTab-14"
                        ref={panel14Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-14" ? getPanelHeight(panel14Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-15" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-15"
                      >
                        <button
                          aria-controls="accPanel-15"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-15")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-15"
                        aria-labelledby="accTab-15"
                        ref={panel15Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-15" ? getPanelHeight(panel15Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-16" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-16"
                      >
                        <button
                          aria-controls="accPanel-16"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-16")}
                        >
                          {contentOptions.question2 &&
                            contentOptions.question2.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-16"
                        aria-labelledby="accTab-16"
                        ref={panel16Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-16" ? getPanelHeight(panel16Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer2 &&
                              contentOptions.answer2.field[contentOptions.currentLang]}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-8" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-17" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-17"
                      >
                        <button
                          aria-controls="accPanel-17"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-17")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-17"
                        aria-labelledby="accTab-17"
                        ref={panel17Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-17" ? getPanelHeight(panel17Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`c-sideTabs__panels__panel ${
                      currentSidePanel == "sideTabs__tab-9" ? "" : "u-hide"
                    }`}
                    role="tabpanel"
                  >
                    <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
                      <span
                        className={`c-accordion__tab${
                          currentPanel == "accPanel-18" ? " c-accordion__tab--active" : ""
                        }`}
                        id="accTab-18"
                      >
                        <button
                          aria-controls="accPanel-18"
                          aria-expanded="false"
                          onClick={() => handleToggle("accPanel-18")}
                        >
                          {contentOptions.question1 &&
                            contentOptions.question1.field[contentOptions.currentLang]}
                          <svg
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden={true}
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </span>
                      <div
                        className="c-accordion__panel"
                        id="accPanel-18"
                        aria-labelledby="accTab-18"
                        ref={panel18Ref}
                        style={{
                          maxHeight:
                            currentPanel === "accPanel-18" ? getPanelHeight(panel18Ref) : null,
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <p>
                            {contentOptions.answer1 &&
                              contentOptions.answer1.field[contentOptions.currentLang]}
                          </p>
                          <p>FAQ: KDT-011411-DVR-02</p>
                          <C_Rating />
                        </div>
                      </div>
                    </div>

                    <div className="s-faqs__cta">
                      {contentOptions.externallinktoggle &&
                      contentOptions.externallinktoggle.checkbox ? (
                        ""
                      ) : (
                        <E_ArrowCTA
                          copy={
                            contentOptions.externallink &&
                            contentOptions.externallink.field[contentOptions.currentLang]
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_.
export default {
  path: "faqs",
  component: S_Faqs,
  navtxt: "FAQs",
  htmlName: "Faqs",
  categoryType: ["web"],
};
