/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import infoGalleryOpts from "./infoGalleryOpts";
// import L_FlexTiles from "../../0_layout/L_Flextiles";
import C_InfoCard from "../../2_components/infoCard/C_InfoCard";

//can't use number of items dd bc of flextiles using children
const S_InfoGalleryInstruction = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/info-gallery",
      outputName: "InfoGallery",
      headerName: "Info Gallery - Instruction",
      tags: null,
      description:
        "The Information Gallery - Instruction is a collection of information displayed in card format along with a call to action.",
      related: null,
      currentTheme: "t-white",
      htmlSaved: true,
      scripts: ["../../js/rexusManager.component.js", "../../js/flexTiles.layout.js"],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width: 335px, Height: 150px"],
        ],
        ["Image Type", ["PNG, JPG, GIF"]],
        ["Title", ["Defaults to an <h3> and should only be text."]],
        ["Copy", ["The copy appears as a paragraph beneath the title. No Character limit."]],
        ["CTA", ["String Text within CTA Button"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ESSz3seRBPBDn0YEw9ZrCJQBEJaliYey0XqRYQoooDAcsA?e=fuCGYP",
        comments: [["Info Gallery Image Size:", ["335px x 150px"]]],
      },
    };
    setAppState(fbVars);

    setContentOptions(infoGalleryOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "" });
  }, []);

  if (!contentOptions || !dimensions) return "...Loading Info Gallery";
  return (
    <>
      {contentOptions.numCards && contentOptions.numCards.selected == "2" ? (
        <section className={`s-infoGallery s-infoGallery--center ${appState.currentTheme}`}>
          <div className="l-inner">
            <div className="s-infoGallery__container">
              <C_InfoCard
                title={
                  contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                    ? contentOptions.card1[0] &&
                      contentOptions.card1[0].field[contentOptions.currentLang]
                    : ""
                }
                copy={
                  contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                }
                cta={
                  contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                    ? contentOptions.card1[2] &&
                      contentOptions.card1[2].field[contentOptions.currentLang]
                    : ""
                }
              />

              <C_InfoCard
                title={
                  contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                    ? contentOptions.card2[0] &&
                      contentOptions.card2[0].field[contentOptions.currentLang]
                    : ""
                }
                copy={
                  contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                }
                cta={
                  contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                    ? contentOptions.card2[2] &&
                      contentOptions.card2[2].field[contentOptions.currentLang]
                    : ""
                }
              />
            </div>
          </div>
        </section>
      ) : (
        <section className={`s-infoGallery ${appState.currentTheme}`}>
          <div className="l-inner">
            {(contentOptions.numCards && contentOptions.numCards.selected == "3") ||
            (contentOptions.numCards && contentOptions.numCards.selected == "4") ||
            (contentOptions.numCards && contentOptions.numCards.selected == "5") ||
            (contentOptions.numCards && contentOptions.numCards.selected == "6") ? (
              <div className="l-grid l-grid--2col@md l-grid--3col@lg s-infoGallery__container">
                <C_InfoCard
                  classes={
                    contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                      ? "c-infoCard--profile"
                      : ""
                  }
                  title={
                    contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                      ? contentOptions.card1[0] &&
                        contentOptions.card1[0].field[contentOptions.currentLang]
                      : ""
                  }
                  copy={
                    contentOptions.card1 &&
                    contentOptions.card1[1] &&
                    contentOptions.card1[1].field[contentOptions.currentLang]
                  }
                  cta={
                    contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                      ? contentOptions.card1[2] &&
                        contentOptions.card1[2].field[contentOptions.currentLang]
                      : ""
                  }
                />

                <C_InfoCard
                  classes={
                    contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                      ? "c-infoCard--profile"
                      : ""
                  }
                  title={
                    contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                      ? contentOptions.card2[0] &&
                        contentOptions.card2[0].field[contentOptions.currentLang]
                      : ""
                  }
                  copy={
                    contentOptions.card2 &&
                    contentOptions.card2[1] &&
                    contentOptions.card2[1].field[contentOptions.currentLang]
                  }
                  cta={
                    contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                      ? contentOptions.card2[2] &&
                        contentOptions.card2[2].field[contentOptions.currentLang]
                      : ""
                  }
                />

                <C_InfoCard
                  classes={
                    contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                      ? "c-infoCard--profile"
                      : ""
                  }
                  title={
                    contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                      ? contentOptions.card3[0] &&
                        contentOptions.card3[0].field[contentOptions.currentLang]
                      : ""
                  }
                  copy={
                    contentOptions.card3[1] &&
                    contentOptions.card3[1].field[contentOptions.currentLang]
                  }
                  cta={
                    contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                      ? contentOptions.card3[2] &&
                        contentOptions.card3[2].field[contentOptions.currentLang]
                      : ""
                  }
                />

                {(contentOptions.numCards && contentOptions.numCards.selected == "4") ||
                (contentOptions.numCards && contentOptions.numCards.selected == "5") ||
                (contentOptions.numCards && contentOptions.numCards.selected == "6") ? (
                  <C_InfoCard
                    classes={
                      contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                        ? "c-infoCard--profile"
                        : ""
                    }
                    title={
                      contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                        ? contentOptions.card4[0] &&
                          contentOptions.card4[0].field[contentOptions.currentLang]
                        : ""
                    }
                    copy={
                      contentOptions.card4[1] &&
                      contentOptions.card4[1].field[contentOptions.currentLang]
                    }
                    cta={
                      contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                        ? contentOptions.card4[2] &&
                          contentOptions.card4[2].field[contentOptions.currentLang]
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
                {(contentOptions.numCards && contentOptions.numCards.selected == "5") ||
                (contentOptions.numCards && contentOptions.numCards.selected == "6") ? (
                  <C_InfoCard
                    classes={
                      contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                        ? "c-infoCard--profile"
                        : ""
                    }
                    title={
                      contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                        ? contentOptions.card5[0] &&
                          contentOptions.card5[0].field[contentOptions.currentLang]
                        : ""
                    }
                    copy={
                      contentOptions.card5[1] &&
                      contentOptions.card5[1].field[contentOptions.currentLang]
                    }
                    cta={
                      contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                        ? contentOptions.card5[2] &&
                          contentOptions.card5[2].field[contentOptions.currentLang]
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
                {contentOptions.numCards && contentOptions.numCards.selected == "6" ? (
                  <C_InfoCard
                    classes={
                      contentOptions.infoType && contentOptions.infoType.selected == "Profile"
                        ? "c-infoCard--profile"
                        : ""
                    }
                    title={
                      contentOptions.titlesoptions && contentOptions.titlesoptions.checkbox == true
                        ? contentOptions.card6[0] &&
                          contentOptions.card6[0].field[contentOptions.currentLang]
                        : ""
                    }
                    copy={
                      contentOptions.card6[1] &&
                      contentOptions.card6[1].field[contentOptions.currentLang]
                    }
                    cta={
                      contentOptions.ctaoptions && contentOptions.ctaoptions.checkbox == true
                        ? contentOptions.card6[2] &&
                          contentOptions.card6[2].field[contentOptions.currentLang]
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      )}
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "info-gallery",
  component: S_InfoGalleryInstruction,
  navtxt: "Info Gallery - Instruction",
  htmlName: "InfoGallery",
  categoryType: ["web"],
};
