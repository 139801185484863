/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import linksOpts from "./linksOpts";

const S_Links = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let lVars = {
      ...appState,
      currentPath: "/ui/links",
      currentTheme: "t-stone",
      outputName: "Links",
      headerName: "Links",
      tags: null,
      description:
        "The Links Section displays a collection of links with optional icons and descriptions.",
      related: null,
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: any as long as the ratio is 1:1",
          ],
        ],
        ["Image Type", ["SVG"]],
        ["Title", ["An <a> tag consists of string text. Character limit: 50"]],
        ["Copy", ["The copy appears as a paragraph beneath the title. Character limit: 100"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Ea4ECGGqY1hJtLfbbZiZwRMBfkX79dosV3_QUEq53YGbgQ?e=OmLq0s",
        comments: [
          [
            "Note",
            [
              "Icons are on Technical Support Links widget and only available on Support related pages.",
            ],
          ],
        ],
      },
    };
    setAppState(lVars);

    setContentOptions(linksOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "186",
      width: window.innerWidth - 24,
    });
  }, []);

  let centerClass = "";
  if (contentOptions && contentOptions.numLinks && contentOptions.numLinks.selected < 3) {
    centerClass = "l-row--center";
  }

  if (!contentOptions) return "...Loading Legal";
  return (
    <section className={"s-links " + appState.currentTheme}>
      <div className="l-inner">
        <ul className={"l-row u-m0 " + centerClass}>
          <li className="l-row__col l-1/2 l-1/3@md">
            <div className="s-links__title">
              {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                <svg className="s-links__icon">
                  <use href={"/images/icons-map.svg" + contentOptions.card1[1].field} />
                </svg>
              ) : (
                ""
              )}

              <a className="u-txt-bold" href="#link1" title="Anchor Title">
                {contentOptions.card1 && contentOptions.card1[0].field[contentOptions.currentLang]}
              </a>
            </div>

            {contentOptions.optNoCopy &&
            contentOptions.optNoCopy.checkbox &&
            contentOptions.card1 ? (
              <p>
                {contentOptions.card1 && contentOptions.card1[2].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
          </li>

          {(contentOptions.numLinks && contentOptions.numLinks.selected == "2") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "3") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "4") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "5") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "6") ? (
            <li className="l-row__col l-1/2 l-1/3@md">
              <div className="s-links__title">
                {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                  <svg className="s-links__icon">
                    <use href={"/images/icons-map.svg" + contentOptions.card2[1].field} />
                  </svg>
                ) : (
                  ""
                )}
                <a className="u-txt-bold" href="#link2" title="Anchor Title">
                  {contentOptions.card2 &&
                    contentOptions.card2[0].field[contentOptions.currentLang]}
                </a>
              </div>
              {contentOptions.optNoCopy &&
              contentOptions.optNoCopy.checkbox &&
              contentOptions.card2 ? (
                <p>
                  {contentOptions.card2 &&
                    contentOptions.card2[2].field[contentOptions.currentLang]}
                </p>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {(contentOptions.numLinks && contentOptions.numLinks.selected == "3") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "4") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "5") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "6") ? (
            <li className="l-row__col l-1/2 l-1/3@md">
              <div className="s-links__title">
                {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                  <svg className="s-links__icon">
                    <use href={"/images/icons-map.svg" + contentOptions.card3[1].field} />
                  </svg>
                ) : (
                  ""
                )}
                <a className="u-txt-bold" href="#link3" title="Anchor Title">
                  {contentOptions.card3 &&
                    contentOptions.card3[0].field[contentOptions.currentLang]}
                </a>
              </div>
              {contentOptions.optNoCopy &&
              contentOptions.optNoCopy.checkbox &&
              contentOptions.card3 ? (
                <p>
                  {contentOptions.card3 &&
                    contentOptions.card3[2].field[contentOptions.currentLang]}
                </p>
              ) : (
                ""
              )}
            </li>
          ) : (
            " "
          )}

          {(contentOptions.numLinks && contentOptions.numLinks.selected == "4") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "5") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "6") ? (
            <li className="l-row__col l-1/2 l-1/3@md">
              <div className="s-links__title">
                {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                  <svg className="s-links__icon">
                    <use href={"/images/icons-map.svg" + contentOptions.card4[1].field} />
                  </svg>
                ) : (
                  ""
                )}
                <a className="u-txt-bold" href="#link4" title="Anchor Title">
                  {contentOptions.card4 &&
                    contentOptions.card4[0].field[contentOptions.currentLang]}
                </a>
              </div>
              {contentOptions.optNoCopy &&
              contentOptions.optNoCopy.checkbox &&
              contentOptions.card4 ? (
                <p>
                  {contentOptions.card4 &&
                    contentOptions.card4[2].field[contentOptions.currentLang]}
                </p>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {(contentOptions.numLinks && contentOptions.numLinks.selected == "5") ||
          (contentOptions.numLinks && contentOptions.numLinks.selected == "6") ? (
            <li className="l-row__col l-1/2 l-1/3@md">
              <div className="s-links__title">
                {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                  <svg className="s-links__icon">
                    <use href={"/images/icons-map.svg" + contentOptions.card5[1].field} />
                  </svg>
                ) : (
                  ""
                )}
                <a className="u-txt-bold" href="#link5" title="Anchor Title">
                  {contentOptions.card5 &&
                    contentOptions.card5[0].field[contentOptions.currentLang]}
                </a>
              </div>
              {contentOptions.optNoCopy &&
              contentOptions.optNoCopy.checkbox &&
              contentOptions.card5 ? (
                <p>
                  {contentOptions.card5 &&
                    contentOptions.card5[2].field[contentOptions.currentLang]}
                </p>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {contentOptions.numLinks && contentOptions.numLinks.selected == "6" ? (
            <li className="l-row__col l-1/2 l-1/3@md">
              <div className="s-links__title">
                {contentOptions.optIcons && contentOptions.optIcons.checkbox ? (
                  <svg className="s-links__icon">
                    <use href={"/images/icons-map.svg" + contentOptions.card5[1].field} />
                  </svg>
                ) : (
                  ""
                )}
                <a className="u-txt-bold" href="#link6" title="Anchor Title">
                  {contentOptions.card6 &&
                    contentOptions.card6[0].field[contentOptions.currentLang]}
                </a>
              </div>
              {contentOptions.optNoCopy &&
              contentOptions.optNoCopy.checkbox &&
              contentOptions.card6 ? (
                <p>
                  {contentOptions.card6 &&
                    contentOptions.card6[2].field[contentOptions.currentLang]}
                </p>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "links",
  component: S_Links,
  navtxt: "Links",
  htmlName: "Links",
  categoryType: ["web"],
};
