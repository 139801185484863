/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
import keyFeatOpts from "./keyFeatOpts";

const S_keyFeature = () => {
  // These are all states that are needed for sections with picture tags.
  const [phone, setPhone] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [desktop, setDesktop] = useState(null);
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    // currentTheme: if a value is set it the theme switcher will show, if null the dropdown is hidden. To set only one with no dropdown, put the theme class in section classNames, i.e. t-white
    // viewHeight is used to autoadjust the height in DimensionsContext
    // viewWidth is set and changed outside the web components, but preserved by DimensionsContext
    // outputName is used by IframeWrapper to preload html if it's done. Should start with an "s".
    // htmlSaved is used to determine if there is html available to load; can't read local file directories from browser
    //HideView will disable the toggle and only show either "Edit Mode" or "Default". "edit" will hide edit mode, and "default" will hide default html view.
    // Specs are what's loaded in the specs tab
    // contentOptions are the fields that are editable in that tab. Put an empty {} for no options.
    let keyVars = {
      ...appState,
      currentPath: "/ui/key-feature",
      outputName: "KeyFeature",
      headerName: "Key Feature",
      tags: ["Landing Page Approved"],
      currentTheme: null,
      description:
        "The Key Feature section has two main areas for dynamic content, the background image and foreground copy. The copy appears over the image when in tablet or desktop view while the text will appear over the bottom of the background in mobile view. Specs below should help determine the most optimal sizing but if the copy height exceeds the height of the image, then you may see some unwanted results.",
      related: null,
      htmlSaved: true,
      hideView: null,
      scripts: [],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*All 3 sizes required",
            "Small: 512px x 750px",
            "Medium: 1024px x 512px",
            "Large: 2048px x 610px",
          ],
        ],
        ["Image Type", ["JPG"]],
        [
          "",
          [
            "*Recommended that images fade to black at the bottom for mobile devices",
            "*Safe zone above content in mobile view is always 20em (320px)",
          ],
        ],
        [
          "Title",
          [
            "*Required. The title can use either of the 6 heading tags <h1>-<h6>. Default is <h4>. It is recommended to use a proper heading tag that is relevant to the page content over visual preference. Character count of <70 is recommended before breaking into 3 lines. Lines break at 30.",
          ],
        ],
        [
          "Copy",
          [
            "Optional. The feature description copy can technically be anything through the use of a WYSIWYG editor, this can include adding in buttons, links and features to launch a modal. Recommend ~200 characters before breaking into 4 lines.",
          ],
        ],
        [
          "CTAs",
          [
            "Optional. Recommended Primary and Secondary buttons with optional play icon (video opens in modal). Character Limit: < 20",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EeCTuHilI8JKl4iuqNYKfnEBJyH4hz2MfCmZUu6gXBI4CQ?e=MCbUl1",
        comments: [
          [
            "Key Features Image Sizes",
            [
              "*All 3 sizes required",
              "Small: 512px x 750px",
              "Medium: 1024px x 512px",
              "Large: 2048px x 610px",
            ],
          ],
          ["Image Type", ["JPG"]],
          [
            "",
            [
              "*Recommended that images fade to black at the bottom for mobile devices",
              "*Safe zone above content in mobile view is always 20em (320px)",
            ],
          ],
          [
            "Title",
            [
              "*Required. The title can use either of the 6 heading tags <h1>-<h6>. Default is <h4>. It is recommended to use a proper heading tag that is relevant to the page content over visual preference. Character count of <70 is recommended before breaking into 3 lines. Lines break at 30.",
            ],
          ],
          [
            "Copy",
            [
              "Optional. The feature description copy can technically be anything through the use of a WYSIWYG editor, this can include adding in buttons, links and features to launch a modal. Recommend ~200 characters before breaking into 4 lines.",
            ],
          ],
          [
            "CTAs",
            [
              "Optional. Use Medium sized buttons from insert button tool. Recommended Primary and Secondary buttons with optional play icon (video opens in modal). Character Limit: < 20",
            ],
          ],
        ],
      },
    };
    setAppState(keyVars);
    // checkbox null makes the field always appear - making that content non-optional.
    // No field, but with a checkbox makes the content optional but not editable.
    // An array in the field value makes it a dropdown. CurrentLang can be left off.
    // Set currentLang to null to leave off translation dropdown from Edit Content Tab.
    //charLimit is a key you can set inside an option object for input field
    setContentOptions(keyFeatOpts);
    //you can set a section as default fullwidth like below width:
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "610",
      width: window.innerWidth - 24,
    });
  }, []);
  //   Customize this loader text
  if (!contentOptions) return "...Loading Key Feature";
  return (
    <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
      <section
        className={`s-keyFeature ${
          contentOptions.txtColor && contentOptions.txtColor.checkbox
            ? "s-keyFeature--txtWhite"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Top Center"
            ? " s-keyFeature--posTC"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Top Right"
            ? " s-keyFeature--posTR"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Middle Left"
            ? " s-keyFeature--posML"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Middle Right"
            ? " s-keyFeature--posMR"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Bottom Left"
            ? " s-keyFeature--posBL"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Bottom Center"
            ? " s-keyFeature--posBC"
            : ""
        }${
          contentOptions.alignment && contentOptions.alignment.selected == "Bottom Right"
            ? " s-keyFeature--posBR"
            : ""
        }`}
      >
        <picture className={`e-picture s-keyFeature__image`} id="js-picture">
          <source
            srcSet={phone && phone.phone ? phone.phone : "/images/fpo/general/512x750.png"}
            media="(max-width:32em)"
          />
          <source
            srcSet={tablet && tablet.tablet ? tablet.tablet : "/images/fpo/general/1024x512.png"}
            media="(max-width:64em)"
          />

          <img
            src={desktop && desktop.desktop ? desktop.desktop : "/images/fpo/general/2048x610.png"}
            alt="Alternate Text"
          />
        </picture>
        <div className="l-inner u-p0">
          <div className={`s-keyFeature__content u-p`}>
            <article>
              <h4>
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
              </h4>
              {contentOptions.desc && contentOptions.desc.checkbox ? (
                <p>{contentOptions.desc.field[contentOptions.currentLang]}</p>
              ) : (
                ""
              )}
              {contentOptions.altBtn && contentOptions.altBtn.checkbox ? (
                <a className="e-btn e-btn--alt2" target="_self" title="Button Title" href="#button">
                  {contentOptions.altBtnPlay && contentOptions.altBtnPlay.checkbox ? (
                    <svg>
                      <use
                        xlink="http://www.w3.org/1999/xlink"
                        xlinkHref="../images/icons-map.svg#play-solid"
                      ></use>
                    </svg>
                  ) : (
                    ""
                  )}
                  <span>
                    {contentOptions.altBtn &&
                      contentOptions.altBtn.field[contentOptions.currentLang]}
                  </span>
                </a>
              ) : (
                ""
              )}
              {contentOptions.btn && contentOptions.btn.checkbox ? (
                <a target="_self" title="Button Title" href="#button" className={`e-btn`}>
                  {contentOptions.btnPlay && contentOptions.btnPlay.checkbox ? (
                    <svg>
                      <use
                        xlink="http://www.w3.org/1999/xlink"
                        xlinkHref="../images/icons-map.svg#play-solid"
                      ></use>
                    </svg>
                  ) : (
                    ""
                  )}
                  <span>
                    {contentOptions.btn && contentOptions.btn.field[contentOptions.currentLang]}
                  </span>
                </a>
              ) : (
                ""
              )}
            </article>
          </div>
        </div>
      </section>
    </DropZone>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
//categoryType sorts where sections appear in menus. value options are "landing page", "web", "brand", "icons", "internal"

export default {
  path: "key-feature",
  component: S_keyFeature,
  navtxt: "Key Feature",
  htmlName: "KeyFeature",
  icon: "key-features",
  categoryType: ["landing page"],
};
