/* This function was taken out of Field.js after it became lengthy when grouping fields was requested */
//Drop Down handled in dd component
//checkbox only fields handled in EditableFields.js state
export const handleFieldChange = (e, options, contentOptions, setContentOptions) => {
  //go through all the section's options in contentOptions context as prop
  Object.keys(options).forEach((prop) => {
    //block grouped fields are an array
    if (Array.isArray(options[prop])) {
      //item is object in block fields array
      options[prop].map((item) => {
        //if field and option name match
        console.log("e.target.name: ", e.target.name);
        console.log("item.label: ", item.label);
        if (item.label === e.target.name) {
          // console.log("passed field and content match test");
          if (item.field !== null && typeof item.field !== "object") {
            item.field = e.target.value;
          } else {
            item.field[options.currentLang] = e.target.value;
          }
          // spread existing options in local state into new object and add new info
          let optionsCopy = { ...options };
          // replace options in contentOptions with our newly edited object copy. Set local state above in useEffect
          setContentOptions({ ...optionsCopy });
        }
      });
    } else if (
      typeof options[prop] === "object" &&
      options[prop].field &&
      !Array.isArray(options[prop])
    ) {
      let optKey = prop;
      let optObj;

      //weed out dd fields
      if (options[prop] !== null) {
        //single text fields have a string unless they have multiple languages
        if (options[prop].field !== null && typeof options[prop].field !== "object") {
          if (options[prop].label === e.target.name) {
            // console.log("should be single text field 1 language");
            //get that option in our local state
            optObj = options[optKey];
            optObj.field = e.target.value;
            // spread existing options in local state into new object and add new info
            let optionsCopy = { ...options };
            // replace options in contentOptions with our newly edited object copy. Set local state above in useEffect
            setContentOptions({ ...optionsCopy });
          }
        } else {
          if (options[prop].label === e.target.name) {
            // console.log("should be single text field w/ multiple languages");
            optObj = options[optKey];
            optObj.field[options.currentLang] = e.target.value;
            // spread existing options in local state into new object and add new info
            let optionsCopy = { ...options };
            // replace options in contentOptions with our newly edited object copy. Set local state above in useEffect
            setContentOptions({ ...optionsCopy });
          }
        }
      }
    }
  });
  //TODO: get this refactored inline version working
  // const getOptionByType = (opt) => {
  //   if (Array.isArray(options[opt])) {
  //     //find label in block array here
  //     let blockLabel = "";
  //     options[opt].map((item, i) => {
  //       if (item.label === e.target.name) {
  //         console.log("options[opt][i]: ", options[opt][i]);
  //         blockLabel = item.label;
  //       }
  //     });
  //     return blockLabel;
  //   } else {
  //     if (options[opt].label === e.target.name) {
  //       return options[opt].label;
  //     }
  //   }
  // };

  // const newVal = (x) => {
  //   // console.log("x.label: ", x.label);
  //   console.log("x.field: ", x.field);
  //   // console.log("e.target.value: ", e.target.value);
  //   console.log("options.currentLang: ", options.currentLang);
  //   if (x.field !== null && typeof x.field !== "object") {
  //     x.field = e.target.value;
  //   } else {
  //     x.field[options.currentLang] = e.target.value;
  //   }
  // };

  // let optArr = Object.keys(contentOptions).filter((opt) => getOptionByType(opt));
  // let optKey = optArr[0];
  // console.log("optKey: ", optKey);

  // if (Array.isArray(options[optKey])) {
  //   //do set correct option here
  //   //one-liner replace obj data
  //   const updatedData = options[optKey].map((opt) =>
  //     opt.label === e.target.name ? { ...opt, field: newVal(opt) } : opt,
  //   );

  //   console.log("updatedData: ", updatedData);
  //   let optionsCopy = { ...options, [optKey]: updatedData };

  //   // replace options in contentOptions with our newly edited array copy
  //   setContentOptions({ ...optionsCopy });
  // } else {
  //   let optObj = options[optKey];

  //   optObj.label === e.target.name ? { ...optObj, field: newVal(optObj) } : optObj;
  //   // make a copy of part of state to be changed; never edit state/context directly - replace it
  //   let optionsCopy = { ...options, [optKey]: optObj };
  //   // replace options in contentOptions with our newly edited array copy
  //   setContentOptions({ ...optionsCopy });
  // }
};
