/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useRef } from "react";

const C_Filter = () => {
  const [showCard, setShowCard] = useState(false);
  const [acc1Open, setAcc1Open] = useState(true);
  const [acc2Open, setAcc2Open] = useState(false);
  const [acc3Open, setAcc3Open] = useState(false);

  //ref to clone to get height
  const panel1Ref = useRef(null);
  const panel2Ref = useRef(null);
  const panel3Ref = useRef(null);

  const getPanelHeight = (panel) => {
    if (panel && panel !== null && panel.current) {
      const clone = panel.current.cloneNode(true);
      let pHeight = "";
      clone.style.position = "absolute";
      clone.style.visibility = "hidden";
      document.body.appendChild(clone);
      pHeight = clone.offsetHeight;
      document.body.removeChild(clone);
      return pHeight;
    }
  };

  const accToggle = (e, num) => {
    e.preventDefault();
    if (num == 1) {
      setAcc1Open(!acc1Open);
    } else if (num == 2) {
      setAcc2Open(!acc2Open);
    } else {
      setAcc3Open(!acc3Open);
    }
  };

  const toggleFilter = (e) => {
    e.preventDefault();
    setShowCard(!showCard);
  };

  return (
    <div className={`c-slideMenu ${showCard ? "c-slideMenu--activeLeft" : ""}`} id="slideMenu0">
      <div
        className={`c-slideMenu__slider c-filterCard ${
          showCard ? "c-slideMenu__slider--active" : ""
        }`}
        data-filterid="filterCard0"
        data-dropdown="false"
      >
        <button
          className="c-slideMenu__slider__toggle"
          aria-expanded={showCard ? true : false}
          onClick={(e) => toggleFilter(e)}
        >
          <svg aria-hidden="true">
            <use
              xlink="http://www.w3.org/1999/xlink"
              xlinkHref="/images/icons-map.svg#filter"
            ></use>
          </svg>
          <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
        </button>
        <div className="c-filterCard__results">
          <span data-results="0">Results</span>
        </div>
        <form
          className="c-slideMenu__slider__panel c-filterCard__details"
          aria-labelledby="sldrTgle-55qw75"
        >
          <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
            <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
              <span className={`c-accordion__tab ${acc1Open ? "c-accordion__tab--active" : ""}`}>
                <button aria-expanded="true" onClick={(e) => accToggle(e, 1)}>
                  Android
                  <svg
                    className="c-accordion__tab__icon"
                    viewBox="0 0 14 8"
                    aria-hidden={false}
                    style={{ fill: "#000" }}
                  >
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                aria-labelledby="accTab-kf3aqk"
                ref={panel1Ref}
                style={{
                  maxHeight: acc1Open ? getPanelHeight(panel1Ref) : null,
                }}
              >
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Android</legend>
                    <ul className="u-list-unstyled">
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID1"
                              id="filterBtnsID1"
                              value="filter1"
                            />
                            <label htmlFor="filterBtnsID3">Filter 1</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID2"
                              id="filterBtnsID2"
                              value="filter2"
                            />
                            <label htmlFor="filterBtnsID2">Filter 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID3"
                              id="filterBtnsID3"
                              value="filter3"
                            />
                            <label htmlFor="filterBtnsID3">Filter 3</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <span
                className={`c-accordion__tab ${acc2Open ? "c-accordion__tab--active" : ""}`}
                onClick={(e) => accToggle(e, 2)}
              >
                <button aria-expanded="true">
                  Client
                  <svg
                    className="c-accordion__tab__icon"
                    viewBox="0 0 14 8"
                    aria-hidden={false}
                    style={{ fill: "#000" }}
                  >
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                id="accPanel-4rwmca"
                ref={panel2Ref}
                style={{
                  maxHeight: acc2Open ? getPanelHeight(panel2Ref) : null,
                }}
              >
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Client</legend>
                    <ul className="u-list-unstyled">
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns1"
                              id="clientBtns1"
                              value="Client 1"
                            />
                            <label htmlFor="clientBtns1">Client 1</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns2"
                              id="clientBtns2"
                              value="client2"
                            />
                            <label htmlFor="clientBtns4">Client 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns3"
                              id="client3"
                              value="client3"
                            />
                            <label htmlFor="client3">Client 3</label>
                          </div>
                        </div>
                      </li>
                      <li className="u-frm-disable" tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns4"
                              id="clientBtns4"
                              value="client4"
                            />
                            <label htmlFor="clientBtns4">Client 4</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="client5"
                              id="clientBtns5"
                              value="client5"
                            />
                            <label htmlFor="clientBtns5">Client 5</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <span className={`c-accordion__tab ${acc3Open ? "c-accordion__tab--active" : ""}`}>
                <button aria-expanded="true" onClick={(e) => accToggle(e, 3)}>
                  Year
                  <svg
                    className="c-accordion__tab__icon"
                    viewBox="0 0 14 8"
                    aria-hidden={false}
                    style={{ fill: "#000" }}
                  >
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                aria-labelledby="accTab-m06452"
                ref={panel3Ref}
                style={{
                  maxHeight: acc3Open ? getPanelHeight(panel3Ref) : null,
                }}
              >
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Year</legend>
                    <ul className="u-list-unstyled">
                      <li className="u-frm-disable" tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="yearBtnsID2"
                              id="yearBtnsID2"
                              value="year2"
                            />
                            <label htmlFor="yearBtnsID2">Year 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="yearBtnsID3"
                              id="yearBtnsID3"
                              value="year3"
                            />
                            <label htmlFor="yearBtnsID3">Year 3</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
            <button className="e-btn e-btn--alt2 c-filterCard__details__cta__reset">Reset</button>
            <button className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close">
              Close
            </button>
            <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default C_Filter;
