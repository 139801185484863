/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Legal = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let lVars = {
      ...appState,
      currentPath: "/ui/legal",
      outputName: "Legal",
      headerName: "Legal",
      tags: null,
      description: "The Legal section serves as a section for legal text with footnotes.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        ["Copy", ["Basic text formatting within a P tag such as bold, superscripts only."]],
        [
          "Footnotes",
          ["Ordered list text for any numbered callouts in the Legal paragraphs. (Optional)"],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERB7a47cXulHlya_8oabHskBtSA5aW8IB9tpTjiDP0ijuQ?e=Ly8eIU",
        comments: null,
      },
    };
    setAppState(lVars);

    setContentOptions({
      text: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      foot: {
        label: "Footnote",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来说，我真的不知道",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор сит амет",
          Fr: "Il faisait chaud dans ce temps-là",
          It: "Faceva caldo con quel tempo",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı, benim için",
          Ukrn: "У таку погоду було спекотн",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
          Kor: "국민경제의 발전을 위한 중요정책의",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨ",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "186",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Legal";
  return (
    <section className="s-legal">
      <div className="l-inner">
        <p>{contentOptions.text && contentOptions.text.field[contentOptions.currentLang]}</p>
        {contentOptions.foot && contentOptions.foot.checkbox ? (
          <ol>
            <li>{contentOptions.foot.field[contentOptions.currentLang]}</li>
          </ol>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "legal",
  component: S_Legal,
  navtxt: "Legal",
  htmlName: "Legal",
  categoryType: ["web"],
};
